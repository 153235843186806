.breadcrumb {
  background-color: transparent;
  padding: 0;
  font-size: 0.8em;
  .breadcrumb-item {
    &.active {
      color: #333;
    }
  }
}
