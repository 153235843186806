.route-freight-upload {
  h2 {
    margin: $grid-gutter-width / 2 0;
    div {
      font-size: 1.2rem;
      opacity: .5;
    }
  }
  .box {
    border: none;
    border-radius: 0;
    margin-bottom: 1rem;
  }

  .info {
    color: $danger;
  }

  .file-import {
    border-radius: 2px;
    border: 1px dashed #999;
    background-color: white;
    text-align: center;
    padding: $grid-gutter-width;
    height: 120px;
    position: relative;
    cursor: pointer;
    .form-container {
      position: static;
    }
    .file-upload {
      p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  .freight-upload-form {
    .form-group {
      margin-bottom: 0;
    }
  }

  .freight-upload-result {
    .card:not(:last-child) {
      border-bottom: none;
    }

    .card {
      .card-body {
        font-size: .8rem;
        padding: .75rem;
        .table {
          margin-bottom: 0;
          thead tr th {
            padding: 0.5rem;
            vertical-align: top;
            text-align: center;
            text-transform: uppercase;
            .form-group {
              margin-bottom: 0;
            }
            &.nowrap {
              white-space: nowrap;
            }
          }
          tbody tr td {
            padding: 0.5rem;
            &.nowrap {
              white-space: nowrap;
            }
            .form-group:last-child {
              margin-bottom: 0;
            }
            .inline-checkbox {
              strong {
                font-weight: normal;
              }
            }
          }
          input, select {
            font-size: .8rem;
          }
        }
      }
    }
  }
  .freight-upload-quotes {
    background-color: #fff;
    .table {
      font-size: 0.8rem;
      thead tr th {
        padding: 0.5rem;
        vertical-align: top;
        text-align: center;
        text-transform: uppercase;
      }
      tbody tr td {
        padding: 0.5rem;
      }
      .cheapest {
        color:$danger;
      }
    }
  }
}