.box-bordered {
  border: 1px solid rgba(black, 0.25);
  padding: $grid-gutter-width;
  border-radius: 0.5rem;
  margin-bottom: $grid-gutter-width;

  h4 {
    margin: 0 0 0.25rem;
  }
  h5 {
    margin: 0 0 $grid-gutter-width;
    font-size: 0.9rem;
    opacity: 0.5;
  }

  table {
    width: 100%;
    margin: 0.5rem 0 0;
    tr {
      &:last-child {
        th, td {
          padding-bottom: 0;
        }
      }
      & + tr {
        border-top: 1px solid rgba(black, 0.15);
      }
    }
    th {
      text-transform: uppercase;
      font-size: 0.6rem;
      font-weight: normal;
      padding: 0.75rem 0;
      vertical-align: middle;
      span {
        font-size: 1rem;
        margin-right: 0.5rem;
        position: relative;
        top: 2px;
      }
    }
    td {
      font-size: 0.8rem;
      padding: 0.75rem 0;
      vertical-align: middle;
    }
  }

}
