.single-form-control {
  .form-group {
    margin: 0;
  }
}

.form-group-fixed-actions {
  padding: $grid-gutter-width;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  border-top: 1px solid #e0e7ee;
  background-color: #f5f8fa;
}

.form-group {
  &.form-group-inset-label {
    position: relative;
    .react-select__control,
    .form-control {
      background-color: #f5f8fa;
      border-color: #e6e8ea;
    }
    .react-select--is-disabled .react-select__control {
      background: white;
    }
    .form-label {
      position: absolute;
      top: 9px;
      left: 10px;
      z-index: 1;
      transition: all .3s;
      font-size: 0.9rem;
      color: #787a7b;
    }
    &.has-error {
      .react-select__control,
      .form-control {
        border-color: $danger;
      }
      .form-label {
        color: $danger;
      }
    }
    &.has-value {
      .form-label {
        top: -9px;
        font-size: 0.8rem;
        &:before {
          content: '';
          position: absolute;
          left: -4px;
          right: -4px;
          top: 0;
          height: 10px;
          background-color: white;
          z-index: -1;
        }

      }
    }
    .form-text {
      text-align: right;
      opacity: 0.5;
    }
  }
}

.no-margin .form-group {
  margin-bottom: 0;
}

.form-group-rating {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .react-stars {

  }
  & > div {
    &:last-child {
      margin-left: 1rem;
    }
  }

}
