.address-card {
  border-radius: 4px;
  border: 1px solid #D8DDE6;
  background-color: #F4F6F9;
  padding: $grid-gutter-width / 2;
  label {
    color: #54698D;
    display: block;
    font-size: 0.8em;
    margin-bottom: 0;
  }
  p {
    margin: 0;
    font-size: 0.9em;
    & + label {
      margin-top: $grid-gutter-width / 3;
    }
    &.name {
      color: #006DD5;
    }
  }
  .location {
    line-height: 34px;
    font-size: 1.25em;
    margin-bottom: $grid-gutter-width / 1.5;
    .fa {
      color: white;
      background-color: #D1B043;
      width: 34px;
      height: 34px;
      text-align: center;
      border-radius: 2px;
      line-height: 34px;
      display: inline-block;
      margin-right: $grid-gutter-width / 3;
    }
  }
}
