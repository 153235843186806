.route-homepage {

  h1 {
    margin-top: $grid-gutter-width * 2;
    margin-bottom: 0;
  }
  h1, h2 {
    font-size: 48px;
  }
  h2 {
    margin-bottom: $grid-gutter-width / 1.5;
  }
  h3 {
    font-size: 24px;
    font-weight: normal;
    opacity: .6;
    margin-bottom: $grid-gutter-width * 1.5;
  }

  .homepage-card {
    min-height: 100px;
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    margin-bottom: $grid-gutter-width;
    &.disabled {
      pointer-events: none;
      box-shadow: none;
      opacity: .5;
    }
    .body {
      display: flex;
      flex-direction: row;
      padding: $grid-gutter-width / 2;
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width / 1.5;
      }
      a {
        color: $body-color;
        flex-grow: 1;
        transition: all .35s;
        h4 {
          margin: 0;
          margin-top: $grid-gutter-width / 8;
          font-size: 1.15rem;
        }
        p {
          margin: 0;
          font-size: 0.75rem;
          color: rgba($body-color, 0.65);
          line-height: 1.2;
        }
        .icon {
          float: left;
          font-size: 1.5rem;
          background-color: rgba(theme-color("primary"), .2);
          margin: 0 $grid-gutter-width / 2 0 0;
          width: $grid-gutter-width * 2;
          height: $grid-gutter-width * 2;
          line-height: $grid-gutter-width * 2;
          border-radius: 50%;
          text-align: center;
          color: theme-color("primary");
          @include media-breakpoint-up(lg) {
            margin-right: $grid-gutter-width / 1.5;
          }
        }
        &:hover {
          text-decoration: none;
          color: $primary;
        }
      }
      .submenu-toggle {
        font-size: 1.2rem;
        margin-left: $grid-gutter-width / 2;
        transition: all .35s;
        cursor: pointer;
        color: rgba(black, 0.65);
        @include media-breakpoint-up(lg) {
          margin-left: $grid-gutter-width / 1.5;
        }
        span {
          margin-top: 1.25rem;
        }
        &:hover {
          color: black;
        }
      }
    }
    .submenu {
      border-top: 1px solid rgba(black, 0.125);
      padding: $grid-gutter-width / 0.5 $grid-gutter-width / 1.5;
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width / 2 $grid-gutter-width;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
          position: relative;
          a {
            text-transform: uppercase;
            font-size: 0.8rem;
            padding: $grid-gutter-width / 2 0 $grid-gutter-width / 2 $grid-gutter-width / 1.5;
            display: block;
            transition: all .35s;
            span {
              position: absolute;
              left: 0;
              top: 19px;
            }
            &:hover {
              color: black;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

}
