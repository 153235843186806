.route-freight-quote {

  h2 {
    margin: $grid-gutter-width / 2 0;
    div {
      font-size: 1.2rem;
      opacity: .5;
    }
  }
  h4 {
    margin: 0 0 $grid-gutter-width / 2;
  }
  h5 {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: $grid-gutter-width 0 $grid-gutter-width / 2;
  }

  .info {
    color: $danger;
  }

  .box {
    border: none;
    border-radius: 0;
    .table {
      thead {
        tr {
          background-color: rgba(black, 0.1);
        }
        th {
          font-size: 0.8rem;
          text-transform: uppercase;
        }
      }
      tbody,
      tfoot {
        td {
          vertical-align: middle;
        }
      }
      tfoot {
        .form-group {
          margin-left: 15px;
          display: inline-block;
          max-width: 250px;
        }
        .btn {
          margin-top: 4px;
          margin-left: 15px;
        }
      }
      .form-group {
        margin-bottom: 0;
      }

      &.table-results {
        margin-bottom: 0;
        .comment {
          font-size: 0.8rem;
          color: $danger;
        }
      }
    }
  }

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

}
