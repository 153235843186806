.route-hotlist {
    background-color: white;
    min-height: 100vh;

    .box-table {
        border: none;
        border-radius: 0;

        .table-actions {
            margin-left: -$grid-gutter-width;
            margin-right: -$grid-gutter-width;
        }

        .box-body {
            padding: 0;
        }
    }
}