.manage-po-shipping-info-cards {

  .default-shipping {
    padding: $grid-gutter-width / 2;
    background: white;
    margin-bottom: $grid-gutter-width;
    strong {
      display: block;
      margin: 0;
      font-size: 0.8em;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.45);
    }
    .default-shipping-actions {
      border-top: 1px solid $body-bg;
      padding-top: $grid-gutter-width / 2;
      margin-top: $grid-gutter-width / 2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
}
