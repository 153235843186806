.route-projects-update-vendors {
  .projects-update-vendors-body {
    margin-top: $grid-gutter-width;
    background: white;
    padding: $grid-gutter-width / 1.5;
  }
  .project-info {
    margin: $grid-gutter-width 0;
  }
  .loading {
    &.full-page {
      height: 70vh;
    }
  }
}

.route-projects-misc-charges {
  .projects-update-misc-charges-body {
    margin-top: $grid-gutter-width;
    background: white;
    padding: $grid-gutter-width / 1.5;
  }
  .project-info {
    margin: $grid-gutter-width 0;
  }
  .loading {
    &.full-page {
      height: 70vh;
    }
  }
}

.route-projects-update-shipping {
  .project-info {
    margin: $grid-gutter-width 0;
  }
  .loading {
    &.full-page {
      height: 70vh;
    }
  }
}
