.project-info-label {
  background-color: #04844B;
  padding: $grid-gutter-width / 2;
  padding-left: 50px;
  position: relative;
  color: white;
  .btn {
    @include button-variant(white, white);
    float: right;
    color: #04844B;
    box-shadow: 0 1px 3px rgba(black, .2);
    margin-top: 7px;
  }
  .fa {
    position: absolute;
    left: $grid-gutter-width / 2;
    top: 18px;
    font-size: 1.5em;
  }
  div + div {
    font-weight: bold;
    font-size: 0.8em;
  }
  &.green {
    background-color: #04844B;
    .btn {
      color: #04844B;
    }
  }
  &.red {
    background-color: #C23934;
    .btn {
      color: #C23934;
    }
  }
}

.project-info-header {
  background-color: #F7F9FB;
  padding: $grid-gutter-width / 2;
  .symbol {
    color: white;
    background-color: #D1B043;
    width: 34px;
    height: 34px;
    text-align: center;
    border-radius: 2px;
    line-height: 34px;
    display: inline-block;
    vertical-align: middle;
  }
  .project {
    line-height: 1.2;
    display: inline-block;
    vertical-align: middle;
    margin-left: $grid-gutter-width / 2;
    div {
      font-size: 0.8em;
      text-transform: uppercase;
    }
    strong {
      font-size: 1.2em;
    }
  }
  .dropdown {
    float: right;
    .btn-primary {
      background-color: white;
      border-color: #e0e0e0;
      color: $body-color;
    }
  }
  .btn-back {
    float: right;
    background-color: white;
    border-color: #e0e0e0;
    color: $body-color;
  }
}
.project-info-body {
  background-color: white;
  padding: $grid-gutter-width / 2;
  h5 {
    margin: 0;
    font-size: 0.8em;
    color: rgba(black, .45);
  }
  h4 {
    margin: 0 0 10px;
    font-size: 0.8em;
    color: rgba(black, .15);
    text-transform: uppercase;
    border-top: 1px solid #F7F9FB;
    margin-top: $grid-gutter-width / 2;
    padding-top: $grid-gutter-width / 2;
  }
  h3 {
    margin: 0 0 5px;
    font-size: 1em;
    color: rgba(black, .45);
  }
  .value {
    &.customer {
      color: #5EA2C9;
      font-size: 1.5em;
    }
    &.large {
      font-size: 1.5em;
    }
    &.small {
      font-size: 0.8em;
      line-height: 1.3;
      padding-top: 5px;
    }
    &.error {
      color: #C23934;
    }
    &.bold {
      font-weight: bold;
      font-size: 1.1em;
    }
    &.address {

    }
  }
  .fa-truck {
    color: #EBEBEB;
    font-size: 64px;
    display: block;
    text-align: right;
    margin: 0 0 $grid-gutter-width / 2 0;
  }
  .note {
    font-size: 0.8em;
    color: rgba(black, .2);
  }
}
