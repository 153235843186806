.rt-dragged-item {
  background-color: #ddd;
  border: 1px solid #ccc;
  font-weight: 700;
  text-align: center;
  display: block;
}

.rt-draggable-container {
  .ReactTable .rt-thead .rt-th {
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  //add a little padding around header text to enlarge hover hotspot
  div.draggable-header.enable-drag {
    padding: 2px 8px;
  }

  //css for when `enableColumnWideDrag` prop is set to {false}
  div[draggable='true'].draggable-header.enable-drag {
    display: inline-flex;
  }

  .draggable-header.enable-drag:hover {
    cursor: move;
  }

  //support for enableColumnWideDrag set to true/false
  div.transparent-border,
  div[draggable='true'] {
    border: 1px solid transparent;
  }

  //swap mode
  div.transparent-border.rt-drag-enter-item,
  div[draggable='true'].rt-drag-enter-item {
    border: 1px dashed #777;
  }

  /*Arrow*/
  @mixin rotate($angle) {
    -moz-transform: rotate($angle);
    -ms-transform: rotate($angle);
    -webkit-transform: rotate($angle);
    transform: rotate($angle);
  }

  @mixin arrow(
    $arrowSize: 20px,
    $arrowBorderSize: 3px,
    $arrowBarWidth: 2px,
    $arrowBarHeight: 25px
  ) {
    display: inline-block;
    height: $arrowSize;
    position: relative;
    width: $arrowSize;

    &::after {
      border-bottom-style: solid;
      border-bottom-width: $arrowBorderSize;
      border-right-style: solid;
      border-right-width: $arrowBorderSize;
      content: '';
      display: inline-block;
      height: $arrowSize;
      left: 0;
      position: absolute;
      top: 0;
      width: $arrowSize;
    }

    // Arrow Bar
    &.arrow-bar::before {
      bottom: 0px;
      content: '';
      height: $arrowBarHeight;
      position: absolute;
      right: -1px;

      transform-origin: bottom;
      width: $arrowBarWidth;
      @include rotate(-45deg);
    }

    // States
    &.is-top {
      @include rotate(225deg);
    }

    &.is-right {
      @include rotate(315deg);
    }

    &.is-bottom {
      @include rotate(45deg);
    }

    &.is-left {
      @include rotate(135deg);
    }
  }

  @mixin arrowColor($color) {
    &,
    .arrow {
      &::after {
        border-color: $color;
      }

      &::before {
        background-color: $color;
      }
    }
  }

  .arrow {
    @include arrow(8px, 2px, 2px, 17px);
    @include arrowColor(#000);
  }
}
