.pill-group {
  margin-bottom: $grid-gutter-width;
  .pill {
    border-radius: 0.75rem;
    border: 1px solid $input-border-color;
    padding: 0.35rem 0.75rem;
    margin-bottom: 0.35rem;
    margin-right: 0.35rem;
    display: inline-block;
    font-size: 0.8rem;
    .fa {
      margin-left: 0.75rem;
      position: relative;
      top: 1px;
      color: $danger;
    }
  }
}

.pill-group-items {
  margin-bottom: $grid-gutter-width;
  .pill {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8rem;
    .name {
      font-weight: bold;
      vertical-align: middle;
      padding-top: $grid-gutter-width / 4;
    }
    .controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 0.5rem;
      .form-group {
        margin: 0 0.5rem;
      }
      .form-control {
        width: 2.5rem;
      }
    }
    & + .pill {
      border-top: 1px solid $input-border-color;
      margin-top: $grid-gutter-width / 2;
      padding-top: $grid-gutter-width / 2;
    }
  }
}
