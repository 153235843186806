.route-coupons-search {
    background-color: white;
    min-height: 100vh;

    .box {
        border: none;
        border-radius: 0;

        .box-body {
            padding: 0;
        }
    }

    .loading {
        &.full-page {
            height: 70vh;
        }
    }

    .filter-button {
        padding-left: 2rem;
        padding-right: 2rem;

        &.extra {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
}