.manage-po-shipping-info-table {

  .default-shipping {
    margin-bottom: $grid-gutter-width;
    .default-shipping-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .table {
    border: 1px solid $table-border-color;
    margin-bottom: 0;
    .inline-checkbox .checkbox {
      background-color: white;
    }
    thead {
      font-size: 0.8rem;
      tr {
        background-color: #f6f8fa;
      }
      th {
        border-bottom-width: 1px;
      }
      .form-group {
        margin: 0;
      }
    }
    tbody {
      tr {
        font-size: 0.8rem;
        &.has-changed {
          background-color: #ffffc7;
          &:nth-child(even) {
            background-color: #ffffc7;
          }
        }
        &:nth-child(even) {
          background-color: #f6f8fa;
        }
        .btn {
          text-transform: uppercase;
          padding: 0;
          &.btn-undo {
            color: $danger;
            margin-right: 0.5rem;
          }
        }
        .table-actions {
          text-align: right;
          white-space: nowrap;
        }
      }
    }
  }

}
