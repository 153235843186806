.data-group {

  & + .data-group {
    margin-top: $grid-gutter-width / 3;
    @include media-breakpoint-up(md) {
      margin-top: $grid-gutter-width / 2;
    }
  }

  .muted-Title{
    color:#808080;
    font-size:'smaller';
  }

}
