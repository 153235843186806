.comments {
  .comment {
    .name {
      font-size: 1.3rem;
      line-height: 1;
      opacity: .35;
    }
    .date {
      font-size: 0.8rem;
    }
    .text {
      margin-top: 15px;
      padding: 1rem;
      border-radius: 15px;
      background-color: rgba(black, 0.05);
      position: relative;
      &:after {
        position: absolute;
        top: -10px;
        left: 15px;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent rgba(black, 0.05) transparent;
      }
    }
    .rating {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 8px;
      .progress {
        width: 200px;
        height: 0.7rem;
      }
      .summary {
        margin-left: 15px;
        font-weight: bold;
      }
    }
    & + .comment {
      margin-top: 4rem;
    }
  }
  .show-more {
    margin-top: 1rem;
    text-align: center;
    border-top: 1px solid rgba(black, 0.25);
    padding-top: 10px;
    color: $primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
