.box-page-tabs {
  border-bottom: 1px solid rgba(black, 0.25);
  margin-top: $grid-gutter-width / -2;
  margin-bottom: $grid-gutter-width;
  ul {
    margin: 0 0 0 $grid-gutter-width / -2;;
    padding: 0;
    list-style: none;
  }
  li {
    display: inline-block;
    & + li {
      margin-left: $grid-gutter-width * 1.5;
    }
    &.active {
      a {
        font-weight: bold;
        box-shadow: 0 -3px 0 $primary inset;
        &:hover {
          color: $body-color;
        }
      }
    }
  }
  a {
    text-transform: uppercase;
    font-size: 0.85rem;
    padding: 0 $grid-gutter-width / 2 $grid-gutter-width / 2;
    display: block;
    color: rgba($body-color, .75);
    position: relative;
    transition: all .35s;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}
