.form-group-search-select {
  .form-group-search-select-toggle {
    position: relative;
    cursor: pointer;
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    padding-right: 4rem;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    white-space: nowrap;
    @include border-radius($input-border-radius, 0);
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
    .actions {
      position: absolute;
      right: 8px;
      top: 0;
      padding: 6px 0;
      display: flex;
      flex-direction: row;
      z-index: 1;
      .separator {
        border-right: 1px solid hsl(0,0%,80%);
        margin-right: 6px;
        padding-right: 8px;
        svg {
          margin-top: 0;
        }
      }
      svg {
        opacity: 0.2;
        margin-top: 2px;
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }
  .dropdown {
    &.show {
      .form-group-search-select-toggle {
        color: #495057;
        background-color: #fff;
        border-color: #bdd9e9;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(94, 162, 201, 0.25);
      }
    }
  }
  .dropdown-menu {
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    width: 100%;
    .dropdown-item-search {
      display: block;
      width: 100%;
      padding: 0.25rem 1rem;
      clear: both;
      margin-bottom: 0.5rem;
      position: relative;
      .form-control {
        padding-right: 26px;
      }
      svg {
        opacity: 0.2;
        position: absolute;
        right: 20px;
        top: 13px;
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
    .dropdown-item {
      white-space: normal;
      padding-left: 1rem;
      padding-right: 1rem;
      &:hover {
        background-color: $primary;
        color: white;
      }
    }
    .scroll {
      height: 250px;
      margin: 0 0 $grid-gutter-width / -2 $grid-gutter-width / -2;
      @include media-breakpoint-up(md) {
        margin: 0 0 $grid-gutter-width / -3 $grid-gutter-width / -3;
      }
    }
  }
}
