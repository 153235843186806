.route-projects-new,
.route-projects-update-import {

  h1 {
    font-size: 48px;
    margin-top: $grid-gutter-width * 2;
  }
  h2 {
    font-size: 24px;
    margin-bottom: $grid-gutter-width * 2;
  }

  .form-group {
    position: relative;
  }

  .loading {
    height: 300px;
    border-radius: 2px;
    border: 1px dashed #999;
    background-color: white;
  }

  .file-import {
    border-radius: 2px;
    border: 1px dashed #999;
    background-color: white;
    text-align: center;
    padding: $grid-gutter-width;
    height: 300px;
    position: relative;
    cursor: pointer;
    .form-container {
      position: static;
    }
    .file-upload {
      p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  .file-info {
    h5 {
      font-size: 0.8em;
      margin: 0;
      color: rgba(black, 0.6);
    }
    div {
      font-size: 0.9em;
      margin-bottom: $grid-gutter-width;
    }
    .btn {
      box-shadow: 0 1px 3px rgba(black, .2);
    }
  }

}

.route-projects-update-import {

  .breadcrumb {
    margin: $grid-gutter-width / 2 0;
  }

  .project-info {
    margin: $grid-gutter-width 0;
  }

  .loading {
    &.full-page {
      height: 70vh;
      border: none;
      background-color: transparent;
    }
  }

  form {
    .loading {
      height: 300px;
      border-radius: 2px;
      border: 1px dashed #999;
      background-color: white;
    }
  }

}
