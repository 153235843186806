.project-items {
  background-color: white;
  .project-items-tabs {
    background-color: #F7F9FB;
    .tab {
      display: inline-block;
      font-size: 0.7em;
      padding: 8px 16px;
      text-transform: uppercase;
      color: #5EA2C9;
      transition: all .3s;
      &.active {
        background-color: white;
        color: $body-color;
      }
      &:hover {
        cursor: pointer;
        color: $body-color;
      }
    }
  }
  .project-items-grid {
    padding: $grid-gutter-width / 2;
    .purchase-order {
      padding: 0;
    }
    .project-info-label {
      margin-bottom: $grid-gutter-width;
    }
  }
}
