.modal-side {

  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 480px;
    height: 100%;
    left: auto;
    transform: translate3d(0, 0, 0);
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    & > form {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;

    }
  }

  .modal-body {
    flex-grow: 1;
  //  height: 100%;
    padding: $grid-gutter-width;

    h5 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
      margin-top: $grid-gutter-width * 1.5;
      margin-bottom: $grid-gutter-width / 2;
    }

    .inline-checkbox {
      strong {
        font-weight: normal;
      }
    }

    .table-data {
      width: 100%;
      margin: $grid-gutter-width 0;
      tbody {
        tr {
          &:first-child {
            th, td {
              border: none;
            }
          }
        }
        th {
          text-transform: uppercase;
          font-size: 0.8rem;
          border-top: 1px solid rgba(black, 0.15);
          padding: 0.5rem 0;
          color: rgba(black, 0.35);
          vertical-align: middle;
        }
        td {
          text-align: right;
          border-top: 1px solid rgba(black, 0.15);
          padding: 0.5rem 0;
          vertical-align: middle;
        }
      }
    }

    .table-deposit {
      width: 100%;
      border: 1px solid #e0e7ee;
      thead {
        tr {
          background-color: #f5f8fa;
          text-align: center;
          th {
            padding: 0.75rem 0.5rem;
            font-size: 0.9rem;
            color: rgba($body-color, 0.65);
            border-bottom: 1px solid #e0e7ee;
          }
        }
      }
      tbody {
        th {
          padding: 0.75rem 0.5rem;
          font-size: 0.9rem;
        }
        td {
          padding: 0.75rem 0.5rem;
          font-size: 0.9rem;
          text-align: right;
        }
        tr:last-child {
          th {
            padding: 0.75rem 0.5rem;
            font-weight: bold;
          }
          td {
            padding: 0.75rem 0.5rem;
            font-size: 1.2rem;
          }
        }
      }
    }

  }

  .modal-footer {
    padding: $grid-gutter-width / 2 $grid-gutter-width;
    border-top: 1px solid rgba(black, 0.15);
    background-color: rgba(black, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &.fade .modal-dialog {
    left: auto;
    right: -480px;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  &.fade.show .modal-dialog {
    left: auto;
    right: 0;
  }


  .modal-header {
    background-color: $primary;
    color: white;
    border-radius: 0;
    padding: $grid-gutter-width / 2 $grid-gutter-width;
    .close {
      padding: $grid-gutter-width / 2 0 $grid-gutter-width / 2 $grid-gutter-width;
      font-size: 2.5rem;
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

}
