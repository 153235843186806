.table-label {
  border-radius:10px;
  margin:5px;
  padding:7px;
  border-style:solid;
  border-color:lightgrey;
  border-width:1px;
  font-size:x-small;
  font-weight:bold
}
