.route-vendors {
  background-color: white;
  min-height: 100vh;

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

  .box {
    border: none;
    border-radius: 0;
    .box-body {
      padding: 0;
    }
  }

  .box-table {
    .table-actions {
      margin-left: -$grid-gutter-width;
      margin-right: -$grid-gutter-width;
    }
  }

}
