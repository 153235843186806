.quote-status {
  .circle {
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background: black;
    display: inline-block;
    margin-bottom: 0.125rem;
    margin-right: 0.25rem;
  }
  &.gray {
    color: #cccbcc;
    .circle {
      background-color: #cccbcc;
    }
  }
  &.blue {
    color: #599dcc;
    .circle {
      background-color: #599dcc;
    }
  }
  &.purple {
    color: #9847a6;
    .circle {
      background-color: #9847a6;
    }
  }
  &.green {
    color: #48b973;
    .circle {
      background-color: #48b973;
    }
  }
  &.red {
    color: #952926;
    .circle {
      background-color: #952926;
    }
  }
  &.orange {
    color: #fd7b5a;
    .circle {
      background-color: #fd7b5a;
    }
  }
}
