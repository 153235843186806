.warehouse-status {
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.75rem;
    border-radius: 4px;
    padding: 1px 6px;
    &.block {
      display: block;
    }
    &.red {
      color: white;
      background-color: #952926;
    }
    &.orange {
      color: white;
      background-color: #fd7b5a;
    }
    &.green {
      color: white;
      background-color: #48b973;
    }
    &.blue {
      color: white;
      background-color: #599dcc;
    }
    &.black {
      color: rgba(black, 0.6);
      background-color: #cccbcc;
    }
}
