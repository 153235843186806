.popover-diff {
  max-width: 800px;
  z-index: 1020;
  .popover-body {
    padding: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    & > div {
      width: 50%;
    }
    table {
      margin-bottom: 0;
      thead {
        tr {
          td, th {
            border-top: 0;
          }
        }
      }
      .action {
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        font-size: 0.75rem;
        padding: 0.1rem 0.25rem;
        &.add {
          background-color: #e3fcef;
          color: #04844B;
        }
        &.delete {
          background-color: #ffebe6;
          color: #C23934;
        }
        &.modify {
          background-color: #ffffc7;
          color: #ab8106;
        }
      }
    }
  }
}

.action {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.1rem 0.25rem;
  &.add {
    background-color: #e3fcef;
    color: #04844B;
  }
  &.delete {
    background-color: #ffebe6;
    color: #C23934;
  }
  &.modify {
    background-color: #ffffc7;
    color: #ab8106;
  }
}
