.route-login {
  .row {
    min-height: 100vh;
  }
  h1 {
    margin: 0 0 $grid-gutter-width;
    font-size: 1.5em;
    white-space: nowrap;
    height: 65px;
    overflow: hidden;
    text-indent: -100%;
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('images/logo.jpg');
  }
  form {
    background: white;
    padding: $grid-gutter-width / 2;
    box-shadow: 0 2px 2px rgba(black, 0.12);
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width / 1.5;
    }
  }
}
