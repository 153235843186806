.timeline {
  .event {
    position: relative;
    padding-bottom: $grid-gutter-width / 2;
    padding-left: 1.5rem;
    margin-bottom: 0.25rem;
    &:last-child {
      &:before {
        display: none;
      }
    }
    &:before {
      width: 1px;
      content: '';
      position: absolute;
      top: 25px;
      bottom: 0;
      left: 7px;
      background-color: rgba(black, 0.15);
    }
    .status {
      position: relative;
      font-size: 0.9rem;
      &:after {
        width: 17px;
        height: 17px;
        content: '';
        position: absolute;
        top: 2px;
        left: -25px;
        background-color: black;
        border: 3px solid white;
        border-radius: 50%;
        z-index: 1;
      }
      &.gray {
        color: #cccbcc;
        &:after {
          background-color: #cccbcc;
        }
      }
      &.blue {
        color: #599dcc;
        &:after {
          background-color: #599dcc;
        }
      }
      &.purple {
        color: #9847a6;
        &:after {
          background-color: #9847a6;
        }
      }
      &.green {
        color: #48b973;
        &:after {
          background-color: #48b973;
        }
      }
      &.red {
        color: #952926;
        &:after {
          background-color: #952926;
        }
      }
      &.orange {
        color: #fd7b5a;
        &:after {
          background-color: #fd7b5a;
        }
      }
    }
    .author {
      font-weight: bold;
      font-size: 0.9rem;
    }
    .date {
      font-size: 0.8rem;
      color: rgba(black, 0.45);
    }
  }
}
