.route-projects-detail {

  .breadcrumb {
    margin: $grid-gutter-width / 2 0;
  }

  .project-status-bar {
    margin: $grid-gutter-width / 2 0 $grid-gutter-width;
  }

  .project-info {
    margin: $grid-gutter-width 0;
  }

  .project-items {
    margin: $grid-gutter-width 0;
    &.update {
      background-color: transparent;
      .project-items-grid {
        background-color: white;
      }
      .purchase-order-summary {
        margin-top: $grid-gutter-width;
        margin-bottom: 0;
      }
      .view-shipping-summary {
        background-color: white;
        padding: $grid-gutter-width / 2;
      }
    }
  }

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

}
