.modal-orphaned-items {
  .modal-footer {
    .btn {
      white-space: nowrap;
    }
  }
}

.modal-email-preview {
  h5 {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  margin-bottom: $grid-gutter-width;
}

.modal-email-preview-body {
  border: 1px solid #ced4da;
  padding: 15px;
  border-radius: 0.25rem;
  .table {
    border: 1px solid #dee2e6;
    thead {
      & > tr {
        background-color: #dee2e6;
        th {
          text-transform: uppercase;
          font-size: 0.75rem;
          border-bottom-width: 1px;
          line-height: 1;
        }
      }
    }
    tbody {
      font-size: 0.9rem;
    }
  }
}

.modal-edit-line-items {
  .modal-content {
    p {
      font-size: 0.8rem;
      color: #acacac
    }

    label {
      font-size: 0.9rem;
    }

    .modal-footer {
      .editLineItemsFooter {
        width: 100%;
        justify-content: space-between;

        button {
          padding-left: 2rem;
          padding-right: 2rem;
        }

        :nth-child(2) {
          button {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
          }
        }
      }
    }
  }

}
