.purchase-order-card {
  background-color: white;
  padding: $grid-gutter-width / 1.5;
  margin-top: $grid-gutter-width / 2;
  border: 1px solid #D8DDE6;
  font-size: .8em;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  strong {
    display: block;
    font-weight: normal;
    font-size: .8em;
    color: #54698D;
  }
  h4 {
    color: #333333;
    font-size: 0.9em;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    span {
      float: right;
    }
  }
  h5 {
    margin-top: 0;
    font-size: 1em;
  }
  .vendor-id-link {
    float: right;
    font-size: 24px;
    .fa-unlink {
      color: #C23934;
    }
    .fa-link {
      color: #04844B;
    }
  }
  &.active {
    color: $body-color;
    background-color: #9AC5DD;
    border-color: darken(#9AC5DD, 10%);
    cursor: default;
    strong {
      color: $body-color;
      color: lighten(#54698D, 10%);
    }
    .vendor-id-link {
      .fa-link,
      .fa-unlink {
        color: #333333;
      }
    }
  }
  &.success {
    border-color: #04844B;
    .corner {
      position: absolute;
      top: 5px;
      right: 4px;
      &:before {
        position: relative;
        z-index: 1;
        color: white;
      }
      &:after {
        content: '';
        position: absolute;
        background-color: #04844B;
        width: 100px;
        height: 100px;
        top: -72px;
        right: -72px;
        transform: rotate(45deg);
      }
    }
    &.active {
      border-color: darken(#9AC5DD, 10%);
      .fa {
        &:after {
          background-color: darken(#9AC5DD, 10%);
        }
      }
    }
  }
}
