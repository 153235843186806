.route-warehouses-new,
.route-warehouses-edit {
  background-color: white;
  min-height: 100vh;

  h2 {
    margin: $grid-gutter-width / 2 0;
  }

  .box {
    border: none;
    border-radius: 0;
    .box-body {
      padding: 0;
    }
  }

  .form-group {
    position: relative;
  }

  .loading {
    height: 300px;
    border-radius: 2px;
    border: 1px dashed #999;
    background-color: white;
  }

  .box {
    border-color: white;
    border-radius: 0;
  }

}
