.purchase-order {
  background: white;
  padding: $grid-gutter-width / 1.5;
  .header {
    display: block;
    color: #54698D;
    font-weight: normal;
    font-size: 0.8em;
  }
  .actions {
    .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      & + .btn {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .table-items {
    margin: $grid-gutter-width / 1.5 0 0;
    border: 2px solid #E1E5EC;
    & > thead,
    & > tbody {
      & > tr > th {
        color: #16325C;
        font-size: 1.1em;
        font-weight: normal;
        text-transform: uppercase;
        border: 2px solid #E1E5EC;
        background-color: #F4F6F9;
      }
    }
    & > tbody {
      address {
        color: #16325C;
        font-size: 0.8em;
        h5 {
          margin: 0;
          font-weight: bold;
          font-size: 1em;
        }
        strong {
          text-transform: uppercase;
        }
      }
      .shipping-info {
        font-size: 1.1em;
        color: #16325C;
        strong {
          text-transform: uppercase;
        }
      }
      .special-instructions {
        color: #C23934;
        font-size: 1.4em;
        font-weight: bold;
        white-space: pre-line;
        h5 {
          margin: 0;
        }
      }
      & > tr {
        & > td {
          border-left: 2px solid #E1E5EC;
        }
      }
      .table {
        border: none;
        margin: -0.75rem 0;
        font-size: 0.8em;
        & > thead {
          & > tr {
            & > th {
              border-top: 0;
              color: rgba(black, .8);
              font-weight: normal;
              white-space: nowrap;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
        & > tbody {
          & > tr {
            & > td {
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
    & > tfoot {
      font-weight: bold;
      td {
        border-top-width: 2px;
      }
    }
  }
}

.purchase-order-public {
  .public-head {
    img {
      max-width: 360px;
    }
    h1 {
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .public-header {
      text-align: right;
      font-weight: bold;
      font-size: 1.4em;
      line-height: 1.1;
      strong {
        display: block;
        color: #54698D;
        font-weight: normal;
        font-size: 0.5em;
      }
      & + .public-header {
        margin-top: 5px;
      }
    }
  }
}
