.quote-proposal-summary {

  .form-group-templates {
    width: 200px;
    float: right;
    margin-top: -60px;
  }

  .form-group-section {
    margin-bottom: -30px;
  }

  .sections {
    clear: both;

    .section {
      border: 1px solid #e0e7ee;
      border-width: 1px 1px 0;
      margin-bottom: $grid-gutter-width;

      &>strong {
        background-color: #b1c2d5;
        display: block;
        font-size: 1.2rem;
        padding: 0.5rem 1rem;

        .form-group {
          margin: 0 $grid-gutter-width / 2;
          display: inline-block;
          min-width: 260px;
        }
      }

      &>.btn {
        float: right;
        margin-top: 8px;
        margin-right: 5px;
        color: $primary;
        background: white;
        min-width: 120px;
      }
    }
  }

  .categories {
    .category {
      &>strong {
        background-color: #f5f8fa;
        display: block;
        border-bottom: 1px solid #e0e7ee;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 0.9rem;

        .form-group {
          margin: 0 $grid-gutter-width / 2 0 0;
          display: inline-block;
          min-width: 260px;
        }
      }

      &>.btn {
        float: right;
        margin-top: 8px;
        margin-right: 5px;
        color: $primary;
        background: white;
        min-width: 120px;
      }
    }
  }

  .items {
    border-bottom: 1px solid #e0e7ee;

    .item-header {
      padding: 0.75rem 1rem;
      text-transform: uppercase;
      font-size: .8rem;
      color: #495057;
    }

    .item-body {
      padding: 0 1rem;
    }

    .item {
      border-bottom: 1px solid #e0e7ee;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 0.9rem;
      padding: 0.75rem 1rem;

      &.has-options {
        strong .fa {
          opacity: 1;
        }
      }

      .price {
        text-align: right;

        .form-group {
          margin: 0;
          display: inline-block;
          min-width: 260px;
        }
      }

      span {
        margin-right: 0.5rem;
        pointer-events: none;
        .fa {
          margin-right: 0.3rem;
          color: rgba(109, 160, 197, 1)
        }
      }

      strong {
        font-weight: normal;
        flex: 0.6;

        .form-group {
          margin: 0;
          display: inline-block;
          min-width: 260px;
        }

        .fa {
          opacity: 0;
          margin-right: 0.5rem;
          color: #c1daeb;
        }

        .dropdown {
          display: inline-block;
          margin-left: $grid-gutter-width / 2;

          .dropdown-toggle {
            .fa {
              margin: 0;
              opacity: 1;
              color: $body-color;
            }

            &:after {
              display: none;
            }

            &:hover {
              color: white;

              .fa {
                color: white;
              }
            }
          }
        }
      }
    }
  }

  .row-totals {
    align-items: flex-end;
  }

  .table-total {
    width: 100%;
    border: 1px solid #e0e7ee;

    thead {
      tr {
        background-color: #f5f8fa;
        text-align: center;

        th {
          padding: 0.75rem 0.5rem;
          font-size: 0.9rem;
          color: rgba($body-color, 0.65);
          border-bottom: 1px solid #e0e7ee;
        }
      }
    }

    tbody {
      th {
        padding: 0.75rem 0.5rem;
        font-size: 0.9rem;
      }

      td {
        padding: 0.75rem 0.5rem;
        font-size: 0.9rem;
        text-align: right;

        .form-group {
          margin: 0;
          display: inline-block;
        }
      }

      tr:last-child {
        th {
          padding: 0.75rem 0.5rem;
          font-weight: bold;
        }

        td {
          padding: 0.75rem 0.5rem;
          font-size: 1.2rem;
        }
      }
    }
  }
}