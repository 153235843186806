.update-shipping-info-accordion {
  .accordion-group {
    margin-top: $grid-gutter-width;
    &.active {
      .accordion-group-header {
        background-color: black;
        color: white;
        & > div {
          span {
            background-color: white;
            color: black;
            border-color: white;
          }
        }
      }
      .accordion-group-body {
        display: block;
      }
    }
    .accordion-group-body {
      display: none;
    }
  }
  .accordion-group-header {
    padding: $grid-gutter-width / 1.5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background: white;
    & > div {
      font-size: 1.25rem;
      line-height: 1.75rem;
      span {
        display: inline-block;
        width: 1.75rem;
        height: 1.75rem;
        line-height: 1.75rem;
        border-radius: 50%;
        border: 1px solid;
        font-size: 1rem;
        text-align: center;
        vertical-align: bottom;
        margin-right: 0.5rem;
      }
    }
    .fa {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  .accordion-group-body {
    padding: $grid-gutter-width / 2;
    background: white;
    &.po-list {
      padding: 0;
      background-color: transparent;
    }
  }
}
