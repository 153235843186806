.view-shipping-summary {

  .menu {
    margin-bottom: $grid-gutter-width;
    strong {
      display: block;
      font-size: 0.9rem;
      color: rgba(0, 0, 0, 0.45);
      font-weight: normal;
      margin-bottom: 0.25rem;
    }
    .row {
      margin-left: -0.25rem;
      margin-right: -0.25rem;
      .col,
      [class^="col-"] {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
      .btn {
        width: 100%;
      }
    }
  }

  .groups {
    .group {
      margin-bottom: $grid-gutter-width / 2;
      .group-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: $grid-gutter-width / 3;
        background-color: rgba(0, 0, 0, 0.05);
        cursor: pointer;
        position: relative;
        align-items: center;
        .toggle {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translate3d(0, -50%, 0);
        }
        .total {
          border-radius: 50%;
          position: absolute;
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          background-color: black;
          color: white;
          text-align: center;
          right: -0.5rem;
          top: -0.5rem;
          font-size: 0.75rem;
        }
        .icon {
          background-color: black;
          border-radius: 4px;
          padding: 25px;
          color: white;
          font-size: 1.5rem;
          text-align: center;
          margin-right: $grid-gutter-width / 3;
          position: relative;
          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
          & + div {
            flex-grow: 1;
            line-height: 1.2;
            padding-right: 3rem;
          }
        }
        div {
          font-size: 0.8rem;
          p {
            margin: 0;
          }
          strong {
            display: block;
          }
        }
      }
      .group-item {
        margin-top: $grid-gutter-width / 2;
        font-size: 0.8rem;
        display: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
        p {
          margin: 0;
        }
        & + .group-item {
          padding-top: $grid-gutter-width / 2;
          border-top: 1px dashed;
        }
      }
      &.active {
        .group-item {
          display: block;
        }
      }
    }
  }

}
