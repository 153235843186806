.purchase-order-summary {
  margin-bottom: $grid-gutter-width;
  background-color: #FFF;
  .purchase-order-summary-header {
    padding: $grid-gutter-width / 2;
    background-color: #F7F9FB;
    .toggle-details {
      float: right;
      background-color: white;
      border-color: #e0e0e0;
      color: $body-color;
    }
    .checkbox-btn {
      float: right;
    }
    .symbol {
      color: white;
      background-color: #000;
      width: 34px;
      height: 34px;
      text-align: center;
      border-radius: 2px;
      line-height: 34px;
      display: inline-block;
      vertical-align: middle;
    }
    .project {
      line-height: 1.2;
      display: inline-block;
      vertical-align: middle;
      margin-left: $grid-gutter-width / 2;
      div {
        font-size: 0.8em;
        text-transform: uppercase;
      }
      strong {
        font-size: 1.2em;
      }
    }
  }
  .purchase-order-summary-body {
    padding: $grid-gutter-width / 2;
    h5 {
      margin: 0;
      font-size: 0.8em;
      color: rgba(black, .45);
    }
    .value {
      &.large {
        font-size: 1.5rem;
      }
      &.small {
        font-size: 0.8em;
        line-height: 1.3;
        padding-top: 5px;
      }
    }
  }
  .purchase-order-summary-items {
    border-top: 1px solid $body-bg;
    padding: $grid-gutter-width / 2;
  }
}
