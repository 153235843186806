.navbar {
  box-shadow: 0 1px 3px rgba(black, 0.2);

  .user {
    vertical-align: middle;
    display: inline-block;
    .btn {
      margin-left: $grid-gutter-width / 2;
    }
  }

  .navbar-brand {
    margin-top: -10px;
    overflow: hidden;
    text-indent: -100%;
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('images/logo.jpg');
  }

}
