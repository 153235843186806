.route-warehouses-search {
  background-color: white;
  min-height: 100vh;

  h2 {
    margin: $grid-gutter-width / 2 0;
  }

  .box {
    border: none;
    border-radius: 0;
    .box-body {
      padding: 0;
    }
  }

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

}
