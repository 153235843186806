.form-group {
  .react-select__menu {
    z-index: 2;
  }
  .react-select__option--is-selected {
    background-color: $primary;
  }
  .react-select__option--is-focused {
    background-color: rgba($primary, 0.25);
    &.react-select__option--is-selected {
      background-color: $primary;
    }
  }
}
