.route-shipments-search,
.route-shipments-detail {
  background-color: white;
  min-height: 100vh;

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

  .box {
    border: 1px solid #ccd6e3;
  }

  .box-table {
    border: none;
    border-radius: 0;

    .table-actions {
      //  margin-left: -$grid-gutter-width;
      //  margin-right: -$grid-gutter-width;
      padding-left: 0;
      padding-right: 0;
    }

    .box-body {
      padding: 0;
    }
  }

  .btn-primary-grey {
    background-color: gray;
    color: white;
  }

  .btn-warning-secondary {
    background-color: #c29934;
    color: black;
  }

}

.po-table {
  font-size: 13px;

  thead th {
    border-bottom: none;
    white-space: nowrap;
    text-align: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  tbody td.nowrap {
    white-space: nowrap;
  }

  .po-checkbox {
    :disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.shipment-form {
  .accordion {
    padding-top: 0.5rem;

    .card-header {
      font-weight: bold;
    }
  }

  .form-label {
    font-size: 90%;
    font-weight: bold;
  }

  .po-summary {
    padding: 0 15px;
    margin-bottom: 15px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;

    .column {
      padding: 10px 15px;
    }

    .column:not(:last-child) {
      border-right: 1px solid hsl(0, 0%, 80%);
    }
  }
}

.shipment-logs-table {
  font-size: 0.8em !important;
}

.shipment-webtrack.modal-footer {
  display: block;
  text-align: center;
}

.shipment-webtrack-title {
  width: 100%;
  text-align: center;
}

.shipment-webtrack-body {
  label {
    text-align: right;
    width: 100%;
    font-size: 90%;
    font-weight: bold;
  }
}

.table-edit-shipments {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 5rem;
  overflow: auto;
  min-height: 500px;

  .table {
    border-bottom: 10px;
    overflow-x: visible;

    thead {
      tr {
        background-color: #F7F9FB;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
      }

      th {
        font-weight: bold;
        padding: 5px;
        text-align: center;
        line-height: normal;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        border-top: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 0.9em;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;

        &:last-child {
          border-right: none;
        }
      }
    }

    tbody {
      td {
        padding: 0.25rem;
        min-width: 175px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);

        &:last-child {
          border-right: none;
        }

        &.td-details {
          min-width: 1px;
          text-align: center;
          vertical-align: middle;
        }
      }

      tr:nth-child(even) {
        background-color: #F4F6F9;
      }

      .form-group {
        margin: 0;
      }
    }
  }
}