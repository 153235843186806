.route-customers-companies-search,
.route-customers-companies-detail,
.route-customers-users-search,
.route-customers-users-detail,
.route-customers-locations-search,
.route-customers-locations-detail {
  background-color: white;
  min-height: 100vh;

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

  .box {
    border: none;
    border-radius: 0;
    .box-body {
      padding: 0;
    }
  }

  .box-table {
    .table-actions {
      margin-left: -$grid-gutter-width;
      margin-right: -$grid-gutter-width;
    }
  }

}

.route-customers-companies-detail,
.route-customers-users-detail,
.route-customers-locaitons-detail {

  .box-empty-customers {
    margin: 0 0 $grid-gutter-width;
    padding: $grid-gutter-width * 2;
    text-align: center;
    background-color: #f5f8fa;
    border-radius: 2px;
    border: 1px solid #ccd6e3;
    .icon {
      font-size: 3rem;
      margin-bottom: $grid-gutter-width / 2;
      opacity: 0.25;
    }
    .btn + .btn {
      margin-left: $grid-gutter-width / 2;
    }
  }

  .customers-actions {
    text-align: right;
    .btn + .btn {
      margin-left: $grid-gutter-width / 2;
    }
  }

}

.route-customers-locations-detail {
  .configure-status-tracker-form {
    .step {
      border: 1px solid rgba(black, 0.1);
      padding: $grid-gutter-width / 2;
      border-radius: 0.5rem;
      margin-top: $grid-gutter-width / 2;
      .form-group {
    //    margin: 0;
      }
      .step-number {
        span {
          display: inline-block;
          width: 2.25rem;
          height: 2.25rem;
          line-height: 2.25rem;
          border: 1px solid rgba(black, 0.25);
          text-align: center;
          border-radius: 50%;
        }
      }
      .no-margin {
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
      .step-actions {
        text-align: right;
        padding-top: 0.25rem;
        .btn {
          background: transparent;
          border-color: transparent;
          color: $body-color;
        }
      }
    }
    .add-step {
      padding: $grid-gutter-width / 2;
      border-radius: 0.5rem;
      margin-top: $grid-gutter-width / 2;
      background-color: #f5f8fa;
      border: 1px solid #ccd6e3;
      text-align: center;
      cursor: pointer;
      transition: all .3s;
      span {
        margin-right: 0.25rem;
      }
      &:hover {
        border-color: $primary;
        color: $primary;
      }
    }
    .actions {
      text-align: right;
      margin-top: $grid-gutter-width;
      .btn + .btn {
        margin-left: $grid-gutter-width / 4;
      }
    }
  }

  .configure-status-tracker-preview {
    .step {
      border: 1px solid rgba(black, 0.1);
      padding: $grid-gutter-width / 2;
      border-radius: 0.5rem;
      margin-top: $grid-gutter-width / 2;
      vertical-align: middle;
      .value {
        display: inline-block;
        margin-top: 0.35rem;
        font-size: small;
      }
      .value-value {
        display: inline-block;
        margin-top: 0.35rem;
        font-size: medium;
      }
      .step-number {
        span {
          display: inline-block;
          width: 2.25rem;
          height: 2.25rem;
          line-height: 2.25rem;
          border: 1px solid rgba(black, 0.25);
          text-align: center;
          border-radius: 50%;
        }
      }
    }
  }
}
