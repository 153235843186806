.layout {
  margin: 0 auto;
  @include media-breakpoint-up(sm) {
    max-width: 96%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 1140px;
  }
}

.layout-app-header {
  .dropdown-item {
    cursor: pointer;
  }
}

.layout-alt {
  margin: 0 auto;
  .container {
    @include media-breakpoint-up(sm) {
      max-width: 96%;
    }
    @include media-breakpoint-up(lg) {
      max-width: 1140px;
    }
  }
  .layout-app-header {
    border-bottom: 1px solid rgba(black, 0.25);
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    background-color: $body-bg;
    & > .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    h2 {
      margin: 0;
      white-space: nowrap;
    }
    h3 {
      opacity: 0.5;
      margin: 0;
      font-size: 0.8rem;
      white-space: nowrap;
    }
    .back {
      float: left;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 2.5rem;
      border-radius: 50%;
      border: 1px solid;
      text-align: center;
      color: rgba($body-color, .5);
      margin: 5px $grid-gutter-width / 2 0 0;
      transition: all .35s;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
    .dropdown {
      display: inline-block;
      .dropdown-toggle {
        &:after {
          display: none;
        }
      }
      .dropdown-menu {
        left: auto !important;
        right: 0;
      }
    }
  }
}

.navbar.fixed-top + .layout,
.navbar.fixed-top + .layout-alt {
  padding-top: $grid-gutter-width * 2 + 4;
  padding-bottom: $grid-gutter-width;
}
