.route-ecommerce-orders-search,
.route-ecommerce-orders-detail {
  background-color: white;
  min-height: 100vh;

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

  .box-table {
    border: none;
    border-radius: 0;
    .table-actions {
      margin-left: -$grid-gutter-width;
      margin-right: -$grid-gutter-width;
    }
    .box-body {
      padding: 0;
    }
  }

  .box-bordered {
    table {
      width: 100%;
      tr, th {
        vertical-align: top;
      }
    }
  }

}

.route-ecommerce-orders-detail {

  .shopping-cart {
    .table-subtotal {
      width: 100%;
      margin-top: $grid-gutter-width;
      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-width * 2;
      }
      th {
        text-transform: uppercase;
        opacity: .75;
        font-size: 0.9rem;
        padding: 0.5rem 0;
      }
      td {
        text-align: right;
        font-size: 0.9rem;
      }
      tbody {
        tr {
          border: none;
          &:last-child {
            th, td {
              padding-bottom: 1.25rem;
              border: none;
            }
          }
        }
      }
      tfoot {
        th {
          border-top: 1px solid rgba(black, 0.15);
          opacity: 1;
        }
        td {
          border-top: 1px solid rgba(black, 0.15);
          font-size: 1.25rem;
        }
        th, td {
          padding-top: 1.25rem;
          color: black;
          vertical-align: middle;
        }
      }
    }
    .product {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: $grid-gutter-width;
      & > div {
        flex-grow: 1;
        strong {
          font-size: 0.9rem;
          & + p {
            margin: 0;
            font-size: 0.8rem;
            opacity: .75;
            line-height: 1.2;
          }
        }
        &:first-child {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: white;
          border-radius: 0.5rem;
          height: 4rem;
          width: 4rem;
          flex-grow: 0;
          margin-right: $grid-gutter-width / 2;
          box-shadow: 0 2px 6px 0 rgba(black, 0.125);
          position: relative;
          .qty {
            position: absolute;
            top: -0.75rem;
            right: -0.75rem;
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
            background-color: darken(white, 55%);
            text-align: center;
            font-size: 0.8rem;
            color: white;
          }
        }
        &:last-child {
          text-align: right;
          width: 5rem;
          margin-left: $grid-gutter-width / 2;
          font-size: 0.8rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .btn {
            padding: 0;
            text-transform: none;
            font-size: 0.8rem;
            letter-spacing: normal;
            text-align: right;
          }
        }
      }
    }
  }

}
