.route-reports-search {
  background-color: white;
  min-height: 100vh;

  h2 {
    margin: $grid-gutter-width / 2 0;
  }

  .box {
    border: none;
    border-radius: 0;
    .box-body {
      padding: 0;
    }
  }

  .box-reports-search {
    border: 1px solid hsl(0,0%,80%);
    background-color: white;
    margin-bottom: $grid-gutter-width;
    border-radius: $border-radius;
    padding: $grid-gutter-width / 3;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 2;
    }
    .sales-groups {
      border: 1px solid $input-border-color;
      padding: $grid-gutter-width / 3;
      border-radius: 0.25rem;
      margin-bottom: $grid-gutter-width;
      .form-group {
        margin-bottom: 0;
        & + .form-group {
          margin-top: 0.25rem;
        }
      }
    }
  }

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

}
