.order-status {
  padding: 0.25rem 1rem;
  border-radius: 5rem;
  display: inline-block;
  &.gray {
    background-color: #cccbcc;
  }
  &.blue {
    background-color: #599dcc;
    color: white;
  }
  &.purple {
    background-color: #9847a6;
    color: white;
  }
  &.green {
    background-color: #48b973;
    color: white;
  }
  &.red {
    background-color: #952926;
    color: white;
  }
  &.orange {
    background-color: #fd7b5a;
    color: white;
  }
}
