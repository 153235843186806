.insertion-status {
  h4 {
    margin: 0;
    font-size: 0.9em;
  }
  .countdown {
    margin: 5px 0;
    font-size: 1.25em;
  }
  .progress {
    .progress-bar {
      background-color: #04844B;
    }
  }
}
