.rating-average {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .progress {
    width: 300px;
    height: 0.7rem;
  }
  .summary {
    margin-left: 15px;
  }
}
