@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

@import 'settings.scss';

@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';

@import 'components/address-card';
@import 'components/box';
@import 'components/box-table';
@import 'components/box-bordered';
@import 'components/box-logo';
@import 'components/breadcrumbs';
@import 'components/checkbox-dropdown';
@import 'components/form-datepicker';
@import 'components/form-error';
@import 'components/form-file-upload';
@import 'components/form-react-select';
@import 'components/form-group-search-select';
@import 'components/insertion-status';
@import 'components/layout';
@import 'components/loading';
@import 'components/modals';
@import 'components/navbar';
@import 'components/notifications';
@import 'components/project-info';
@import 'components/project-insertion-status';
@import 'components/project-items';
@import 'components/project-status-bar';
@import 'components/purchase-order-card';
@import 'components/purchase-order-summary';
@import 'components/purchase-order';
@import 'components/react-table';
@import 'components/scroll-box';
@import 'components/send-purchase-orders';
@import 'components/toggle';
@import 'components/table-labels';
@import 'components/update-shipping-info-accordion';
@import 'components/manage-po-shipping-info-cards';
@import 'components/manage-po-shipping-info-cards';
@import 'components/manage-po-shipping-info-table';
@import 'components/view-shipping-summary';
@import 'components/popover-diff';
@import 'components/data-group';
@import 'components/modals-side';
@import 'components/box-page-tabs';
@import 'components/pill-group';
@import 'components/modals-alt';
@import 'components/forms';
@import 'components/quote-status';
@import 'components/quote-proposal-summary';
@import 'components/quote-items';
@import 'components/timeline';
@import 'components/order-status';
@import 'components/rating-average';
@import 'components/comments';
@import 'components/warehouse-status';

@import 'routes/login';
@import 'routes/homepage';
@import 'routes/projects-new';
@import 'routes/projects-detail';
@import 'routes/projects-search';
@import 'routes/projects-update';
@import 'routes/vendor-purchase-order';
@import 'routes/warehouses-search';
@import 'routes/warehouses-new';
@import 'routes/warehouses-detail';
@import 'routes/freight-quote';
@import 'routes/freight_upload';
@import 'routes/customers';
@import 'routes/quotes';
@import 'routes/shipments';
@import 'routes/ecommerce-orders';
@import 'routes/vendors';
@import 'routes/reports';
@import 'routes/coupons-search';
@import 'routes/hotlist';

.modal-1100 {
  max-width: 1100px;
}

.modal-600 {
  max-width: 600px;
}

.required {
  &:after {
    content: '*';
    display: inline-block;
    margin-left: 0.25rem;
  }
}
