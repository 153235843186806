.project-status-bar {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      height: 32px;
      line-height: 32px;
      background-color: #D6D6D6;
      font-size: 0.8em;
      display: inline-block;
      padding: 0 $grid-gutter-width / 2 0 $grid-gutter-width;
      position: relative;
      color: #333;
      margin-bottom: 2px;
      &.green {
        color: white;
        background-color: #04844B;
        &:after {
          border-left-color: #04844B;
        }
      }
      &.red {
        color: white;
        background-color: #C23934;
        &:after {
          border-left-color: #C23934;
        }
      }
      &.blue {
        background-color: #9AC5DD;
        &:after {
          border-left-color: #9AC5DD;
        }
      }
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        &:after {
          display: none;
        }
      }
      &:before {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-left: 18px solid $body-bg;
        position: absolute;
        top: 50%;
        margin-top: -18px;
        left: 100%;
        z-index: 1;
      }
      &:after {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 16px solid #D6D6D6;
        position: absolute;
        top: 50%;
        margin-top: -16px;
        left: 100%;
        z-index: 2;
      }
    }
  }
}
