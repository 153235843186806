.route-quotes-search,
.route-quotes-detail,
.route-quotes-edit {
  background-color: white;
  min-height: 100vh;

  .loading {
    &.full-page {
      height: 70vh;
    }
  }

  .box {
    border: none;
    border-radius: 0;

    .box-body {
      padding: 0;
    }
  }

  .box-table {
    .table-actions {
      margin-left: -$grid-gutter-width;
      margin-right: -$grid-gutter-width;
    }
  }

  .quote-items {
    tbody td {
      vertical-align: top;
    }
  }

  .box-bordered {
    h4 {
      margin: 0 0 $grid-gutter-width;
      font-size: 1.2rem;
    }

    h5 {
      margin: 0 0 $grid-gutter-width / 4;
      text-transform: uppercase;
    }

    p {
      .fa {
        margin-right: 0.35rem;
      }
    }
  }

  .display-control {
    font-size: 0.9rem;
    color: rgba($body-color, 0.55);

    .fa {
      margin-right: 0.35rem;

      &.fa-square {
        opacity: .25;
      }
    }

    &+.display-control {
      margin-top: $grid-gutter-width / 2;
    }
  }

}

.route-quotes-edit {
  form {
    margin-bottom: $grid-gutter-width * 3;
  }

  .quote-error-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5rem;

    p {
      font-size: 0.7rem;
      margin: 0;
    }
  }

  .icon-div {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #dc3545;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0.5rem;
    transform: scale(0.7);

    .errors-parent {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .errors-container {
      display: inline-block;
      margin-bottom: 1rem;
    }

    .edit-error {
      display: flex;
      flex-direction: row;
      align-self: center;
      align-items: center;

      span {
        margin-right: 0.2rem;
      }
    }
  }
}
