.box-logo {
  border: 1px solid rgba(black, 0.25);
  border-radius: 0.5rem;
  margin-bottom: $grid-gutter-width;
  h4 {
    margin: 0 0 0.25rem;
    padding: $grid-gutter-width;
  }
  img {
    display: block;
    max-width: 75%;
    margin: 0 auto $grid-gutter-width;
  }
  .box-body {
    position: relative;
  }
}
