.modal-alt {

  .modal-header {
    background-color: $primary;
    color: white;
    border-radius: 0;
    padding: $grid-gutter-width / 2 $grid-gutter-width;

    .close {
      padding: $grid-gutter-width / 2 0 $grid-gutter-width / 2 $grid-gutter-width;
      font-size: 2.5rem;

      &:focus,
      &:active,
      &:active:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .modal-footer {
    padding: $grid-gutter-width / 2 $grid-gutter-width;
    border-top: 1px solid rgba(black, 0.15);
    background-color: rgba(black, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .form-group {
      margin: 0;

      .inline-checkbox {
        strong {
          font-weight: normal;
        }
      }
    }
  }

  .file-import {
    border-radius: 2px;
    border: 1px dashed #ccd6e3;
    background-color: #f5f8fa;
    text-align: center;
    padding: $grid-gutter-width;
    height: 300px;
    position: relative;
    cursor: pointer;

    .form-container {
      position: static;
    }

    .file-upload {
      p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  .file-info {
    h5 {
      font-size: 0.9rem;
      margin: 0;
      text-transform: uppercase;
    }

    div {
      font-size: 0.9em;
      margin-bottom: $grid-gutter-width;
    }

    .btn {
      box-shadow: 0 1px 3px rgba(black, .2);
    }
  }

  .modal-body {
    .results {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $grid-gutter-width;
      margin-top: $grid-gutter-width / 2;

      .result {
        border-radius: 6px;
        background-color: #eee;
        text-align: center;
        padding: 1.55rem 0.5rem;

        span {
          font-size: 2rem;
          font-weight: bold;
        }

        strong {
          display: block;
          font-size: 0.9rem;
          opacity: .55;
        }

        &.red {
          color: darken($danger, 20%);
          background-color: mix($danger, #eee, 20%);
        }

        &.green {
          color: darken($success, 20%);
          background-color: mix($success, #eee, 20%);
        }
      }
    }

    .exceptions {
      max-width: 90%;
      border-radius: 6px;
      padding: 1rem;
      background-color: mix($danger, white, 7.5%);
      margin: 0 auto $grid-gutter-width / 2;
      border: 1px solid $danger;

      strong {
        font-size: 0.75rem;
        text-transform: uppercase;
        color: $danger;
        display: block;
        padding-bottom: 0.5rem;
      }

      label {
        font-size: 0.8rem;
        color: $danger;
        display: block;
        padding-bottom: 0.5rem;
      }

      .header {
        justify-content: space-between;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
      }

      .exceptions-list {
        max-height: 200px;
        overflow: scroll;
      }

      div {
        font-size: 0.9rem;

        &+div {
          margin-top: $grid-gutter-width / 2;
          padding-top: $grid-gutter-width / 2;
          border-top: 1px solid darken($danger, 20%);
        }
      }
    }
  }

}